export const VALUES = [
  {
    title: "Recirculate",
    hoverText: (
      <>
        We yield uniquely sustainable <br />
        growth by retaining gained IP, <br />
        recirculating resources and <br />
        learnings to avoid waste. The <br />
        same mistakes aren't made twice.
      </>
    ),
    mobileText: (
      <>
        <p className="values__card-title">Recirculate</p>
        <br />
        We yield uniquely sustainable <br />
        growth by retaining gained IP, <br />
        recirculating resources and <br />
        learnings to avoid waste. The <br />
        same mistakes aren't made twice.
      </>
    ),
    hoverColor: "#FBFFAB",
    hoverBg: "#67bd45",
  },
  {
    title: "Keep lean",
    hoverText: (
      <>
        Mean about keeping lean, we <br />
        fold our gains back into a self-
        <br />
        sustaining venture-building
        <br />
        machine. We judge value on <br />
        fruits we reap, not words.
      </>
    ),
    mobileText: (
      <>
        <p className="values__card-title">Keep lean</p>
        <br />
        Mean about keeping lean, we <br />
        fold our gains back into a self-
        <br />
        sustaining venture-building
        <br />
        machine. We judge value on <br />
        fruits we reap, not words.
      </>
    ),
    hoverColor: "#FBFFAB",
    hoverBg: "#67bd45",
  },
  {
    title: "Empower",
    hoverText: (
      <>
        We seek to make the sunshine <br />
        inside all of our stakeholders shine. <br />
        We build empowering platforms <br />
        which reciprocally unlock lost <br />
        synergies and opportunities.
      </>
    ),
    mobileText: (
      <>
        <p className="values__card-title">Empower</p>
        <br />
        We seek to make the sunshine <br />
        inside all of our stakeholders shine.
        <br />
        We build empowering platforms
        <br />
        which reciprocally unlock lost
        <br />
        synergies and opportunities.
      </>
    ),
    hoverColor: "#FBFFAB",
    hoverBg: "#67bd45",
  },
  {
    title: "Organism",
    hoverText: (
      <>
        All living entities and thus <br />
        organisms are a whole <br />
        greater in scope than the <br />
        sum of its isolated parts. <br />
        We cultivate emergent <br />
        links to unlock new utility.
      </>
    ),
    mobileText: (
      <>
        <p className="values__card-title">Organism</p>
        <br />
        All living entities and thus organisms{" "}
        <br className="hide-on-mobile-sm" />
        are a whole greater in scope than the{" "}
        <br className="hide-on-mobile-sm" />
        sum of its isolated parts. We cultivate
        <br className="hide-on-mobile" /> emergent links to unlock new utility.
      </>
    ),
    hoverColor: "#FBFFAB",
    hoverBg: "#67bd45",
  },
  {
    title: "Family",
    hoverText: (
      <>
        We keep things in-house adding <br />
        expert leaders to our team who <br />
        share our values, culture and <br />
        aims. Ethical boundaries optimise <br />
        arrangement of energy and atoms.
      </>
    ),
    mobileText: (
      <>
        <p className="values__card-title">Family</p>
        <br />
        We keep things in-house adding
        <br />
        expert leaders to our team who
        <br />
        share our values, culture and aims.
        <br />
        Ethical boundaries optimise <br />
        arrangement of energy and atoms.
      </>
    ),
    hoverColor: "#FBFFAB",
    hoverBg: "#67bd45",
  },
  {
    title: "Formula",
    hoverText: (
      <>
        Organic Ventures investors <br />
        recycle the principal and the <br />
        equivalent return on each venture, <br />
        extracting any gains above this, <br />
        following nature&apos;s code.
      </>
    ),
    mobileText: (
      <>
        <p className="values__card-title">Formula</p>
        <br />
        Organic Ventures investors recycle <br />
        the principal and the equivalent return{" "}
        <br className="hide-on-mobile" /> on each venture, extracting any gains
        <br className="hide-on-mobile-sm" />
        above this, following nature&apos;s code.
      </>
    ),
    hoverColor: "#FBFFAB",
    hoverBg: "#67bd45",
  },
];
