const ValueCard = ({ title, hoverText, mobileText, idx }) => {
  return (
    <div
      className={`values__card ${
        idx % 2 === 0 ? "values__card--green" : "values__card--white"
      }`}
    >
      <div className="values__card-description">
        <span className="hide-on-desktop">{mobileText}</span>
        <span className="values__card-desktop-title">{title}</span>
        <span className="values__card-desktop-description">{hoverText}</span>
      </div>
    </div>
  );
};

export default ValueCard;
