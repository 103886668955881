import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Logo from "../../assets/organic-ventures-logo.png";
import BgBlue from "../../assets/growth-bg.png";
import BgBlueMobile from "../../assets/growth-bg-mobile.png";
import BgInnovation from "../../assets/growth-innovation-image-new.png";
import GrowthAdminImage from "../../assets/growth-admin-image.png";
import GrowthSmallLogo from "../../assets/growth-small-logo.png";
import WaterPlants from "../../assets/innovation/water-plants.png";

import TechIcon from "../../assets/architecture/globe.png";
import MobileIcon from "../../assets/architecture/phone.png";
import IPIcon from "../../assets/architecture/pin.png";
import BrandingIcon from "../../assets/architecture/target.png";
import MarketingIcon from "../../assets/architecture/idea-cycle.png";
import TeamIcon from "../../assets/architecture/idea.png";
import ExpertIcon from "../../assets/architecture/checkboard.png";
import AdminIcon from "../../assets/architecture/desktop.png";

import GrowthUpArrow from "../../assets/arrow-up.svg";
import SecondCircleBg from "../../assets/circle-bg-2.png";
import SpringBg from "../../assets/spring-bg.png";
import MobileImage from "../../assets/growth-mobile.png";

import MenuOpenImg from "../../assets/menu-open.png";
import MenuCloseImg from "../../assets/menu-close.png";

import { VENTURES } from "../../Constants/ventures";
import { VALUES } from "../../Constants/values";

import "./index.css";
import VentureCard from "../../components/VentureCard";
import ValueCard from "../../components/ValueCard";

function Landing() {
  const [isMobileView, setMobileView] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const growthMainRef = useRef(null);
  const growthVentureRef = useRef(null);
  const growthValueRef = useRef(null);
  const growthAdminRef = useRef(null);

  useEffect(() => {
    checkDeviceStatus();
    window.addEventListener("resize", checkDeviceStatus);
  }, []);

  const checkDeviceStatus = () => {
    setMobileView(window.innerWidth < 1000);
  };

  const handleScrollToTop = () => {
    window.scrollToY(0, 1500, "easeInOutQuint");
  };

  const scrollToRef = (ref, offset) => {
    if (!ref || !ref.current) {
      return;
    }

    handleToggleMenu();

    offset = offset || 0;
    if (ref && ref.current) {
      window.scrollToY(
        ref.current.offsetTop - 70 - offset,
        1500,
        "easeInOutQuint"
      );
    }
  };

  const handleToggleMenu = () => setIsMenuVisible((prev) => !prev);

  return (
    <div className="app-container">
      {!isMobileView && (
        <div className="introduction-container">
          <img
            alt="blue-colored background"
            className="background-blue"
            src={BgBlue}
          />
          <div className="introduction-left">
            <div className="introduction-content">
              <div className="introduction__logo-container">
                <img
                  alt="organic ventures logo"
                  className="introduction-logo"
                  src={Logo}
                  style={{ marginTop: 0, objectFit: "contain" }}
                />
              </div>
              <p className="introduction-header">
                We build ventures
                <span className="introduction-header--green">.</span>
              </p>
              <p className="introduction-sub-header">
                Turning actionable insights into <br />
                <span className="introduction-sub-header--large">
                  market defining companies.
                </span>
              </p>
              <p className="section-description introduction-description">
                Our data-led, insight-oriented, socially validated <br />
                ventures are provided the tools needed to flourish.
              </p>
              <p className="section-description introduction-description">
                Internal support spans; research, strategy, tech, <br />
                funding, team and conversion, limiting the usual <br />
                start-up waste and maximising investor returns.
              </p>
              {/* <div className="button-container">
                <a
                  href="https://innovators.growthlabs.ventures"
                  className="button btn-innovate"
                >
                  <p className="button-text">Innovate</p>
                </a>
                <a
                  className="button btn-invest"
                  href="https://partners.growthlabs.ventures"
                >
                  <img
                    src={GrowthButtonCorner}
                    alt="button corner"
                    className="btn-corner"
                  />
                  <p className="button-text">Invest</p>
                </a>
              </div> */}
            </div>
          </div>
          <div className="introduction-right">
            <img alt="organic ventures hero background" src={MobileImage} />
          </div>
        </div>
      )}
      {isMobileView && (
        <>
          <div className="header-mobile-container">
            <div className="header-mobile-actions">
              <img
                alt="organic ventures logo"
                className="introduction-logo"
                src={Logo}
              />
              <div className="btn-mobile-menu" onClick={handleToggleMenu}>
                <img
                  className="mobile-menu-img"
                  src={isMenuVisible ? MenuCloseImg : MenuOpenImg}
                  alt="mobile menu"
                />
              </div>
            </div>
            {isMenuVisible && (
              <div className="header-mobile-menu">
                <div
                  className="header-menu-button"
                  onClick={() => scrollToRef(growthMainRef)}
                >
                  Home
                </div>
                <div
                  className="header-menu-button"
                  onClick={() => scrollToRef(growthVentureRef, -80)}
                >
                  Ventures
                </div>

                <div
                  className="header-menu-button"
                  onClick={() => scrollToRef(growthValueRef, 50)}
                >
                  Value
                </div>
                <div
                  className="header-menu-button"
                  onClick={() => scrollToRef(growthAdminRef)}
                >
                  Architecture
                </div>
              </div>
            )}
          </div>
          <div className="introduction-mobile-container" ref={growthMainRef}>
            <img
              alt="blue-colored background"
              className="background-blue"
              src={BgBlueMobile}
            />
            <img
              className="introduction-mobile-img"
              alt="organic ventures hero banner"
              src={MobileImage}
            />
            <div className="introduction-content">
              <p className="introduction-header">We build ventures</p>
              <p className="introduction-sub-header">
                Turning actionable insights into <br />
                <span className="introduction-sub-header--large">
                  market defining companies.
                </span>
              </p>
              <p className="section-description introduction-description">
                Our data-led, insight-oriented, socially{" "}
                <br className="hide-on-tablet" />
                validated <br className="hide-on-mobile" />
                ventures are provided the <br className="hide-on-tablet" />
                tools needed to flourish.
              </p>
              <p className="section-description introduction-description">
                Internal support spans; research,{" "}
                <br className="hide-on-tablet" />
                strategy, tech, <br className="hide-on-mobile" />
                funding, team and <br className="hide-on-tablet" />
                conversion, limiting the usual <br className="hide-on-mobile" />
                <br className="hide-on-tablet" />
                start-up waste and maximising
                <br className="hide-on-tablet" /> investor returns.
              </p>
              {/* <div className="button-container">
                <a
                  href="https://innovators.growthlabs.ventures"
                  className="button btn-innovate"
                >
                  <p className="button-text">Innovate</p>
                </a>
                <a
                  className="button btn-invest"
                  href="https://partners.growthlabs.ventures"
                >
                  <img
                    src={GrowthButtonCorner}
                    alt="button corner"
                    className="btn-corner"
                  />
                  <p className="button-text">Invest</p>
                </a>
              </div> */}
            </div>
          </div>
        </>
      )}
      <div
        className="section-container venture-container"
        ref={growthVentureRef}
      >
        <p className="venture__section-header">What we create</p>
        <p className="venture__main-description">
          Unique ventures with <br className="hide-on-desktop" /> revolutionary
          tech.
        </p>
        <p className="venture__section-description">
          We back robust internal growth, <br className="hide-on-desktop" />{" "}
          instead of founder learnings.
        </p>
        <div className="venture-grid-container">
          {VENTURES.map((venture, i) => (
            <VentureCard key={i} {...venture} />
          ))}
        </div>
        <p className="venture-content">
          We turn actionable insights
          <br className="hide-on-tablet" /> into market-defining,
          <br className="hide-on-desktop" /> venture-funded companies
          <br className="hide-on-tablet" /> with rapid adoption.
        </p>
        {/* <div className="button-container">
          <a
            className="button btn-innovate"
            href="https://innovators.growthlabs.ventures"
          >
            <p className="button-text">Innovate</p>
          </a>
          <a
            className="button btn-invest"
            href="https://partners.growthlabs.ventures"
          >
            <img
              src={GrowthButtonCorner}
              alt="button corner"
              className="btn-corner"
            />
            <p className="button-text">Invest</p>
          </a>
        </div> */}
      </div>
      <div className="innovation-container">
        <div className="innovation-image">
          <img src={BgInnovation} alt="innovation" />
          <div className="innovation__overlay-container">
            <img
              src={WaterPlants}
              alt="innovation"
              className="innovation__overlay"
            />
          </div>
        </div>
        <div className="innovation-bg-bottom" />
        <div className="innovation-content">
          <h3 className="innovation__title">Our elevator pitch</h3>
          <div className="innovation__section-text">
            Innovation, from the ground up!
          </div>
          <p className="innovation__section-description">
            We ideate and validate a core insight to inform <br />
            the build-out of each product and each specialist <br />
            team. We then cultivate scale with a clear path
            <br /> to self-sustaining returns.
          </p>
          <h4 className="innovation__sub-title">
            We pour high grade fertiliser <br />
            on seeds of vast potential.
          </h4>
          <p className="innovation__sub-text">
            The lab&apos;s architecture keeps expert venture{" "}
            <br className="hide-on-tablet" />
            leads
            <br className="hide-on-mobile" /> laser-focused on stable growth,
            whilst <br className="hide-on-tablet" />
            avoiding <br className="hide-on-mobile" />
            founder mistakes. Our technical expert
            <br className="hide-on-tablet" /> network{" "}
            <br className="hide-on-mobile" />
            delivers guidance and structure to our
            <br className="hide-on-tablet" /> teams, to{" "}
            <br className="hide-on-mobile" />
            mobilise; agile, inspired and lean <br className="hide-on-tablet" />
            venture ascent.
          </p>
          {/* <div className="button btn-whitepaper">
            <p className="button-text" onClick={() => navigate("/magazine")}>
              ACCESS OUR WHITEPAPER
            </p>
          </div> */}
        </div>
      </div>
      <div className="section-container values__container" ref={growthValueRef}>
        {!isMobileView && (
          <img src={SpringBg} alt="spring bg" className="spring-bg" />
        )}
        <p className="values__section-header">Organically built ventures</p>
        <p className="values__section-main-description">
          We don't make a song and <br className="hide-on-tablet" /> dance out{" "}
          <br className="show-on-tablet" />
          of being organic <br className="hide-on-tablet" /> and ethical.
        </p>
        <p className="values__section-description">
          We trim the fat and faff to robustly <br className="hide-on-tablet" />{" "}
          go about
          <br className="show-on-tablet" /> cultivating killer solutions{" "}
          <br className="hide-on-tablet" />
          to global problems.
        </p>
        <div className="values__grid-container">
          {VALUES.map((value, i) => (
            <ValueCard {...value} key={i} idx={i} />
          ))}
        </div>
      </div>

      <h3 className="admin__title" ref={growthAdminRef}>
        Our Architecture
      </h3>
      <div className="section-container admin__container">
        {!isMobileView && (
          <img
            src={SecondCircleBg}
            className="admin__circle-bg"
            alt="admin circle bg"
          />
        )}
        <div className="section-content">
          <div className="admin__section-top">
            <div className="admin__image">
              <img src={GrowthAdminImage} alt="admin" />
            </div>
            <div className="admin__section-information">
              <p className="admin__header">
                Startups fail because they <br className="hide-on-tablet" />
                waste money <br className="hide-on-mobile" />
                and don&apos;t laser <br className="hide-on-tablet" />
                focus on scalability.
              </p>
              <p className="admin__section-description">
                We calibrate risk management from{" "}
                <br className="hide-on-tablet" /> day one with our{" "}
                <br className="hide-on-mobile" />
                menu of essential <br className="hide-on-tablet" />
                tools. We liberate our leads from{" "}
                <br className="hide-on-tablet" />
                the <br className="hide-on-mobile" />
                typical "start-up killers", to <br className="hide-on-tablet" />{" "}
                focus time on value creation.
              </p>
            </div>
          </div>
          <div className="admin__section-grid-container">
            <div className="admin__section-card">
              <div className="admin__img-container">
                <img
                  className="admin__section-card-logo"
                  src={GrowthSmallLogo}
                  alt="section-card-logo"
                />
                <img
                  className="admin__section-card-icon"
                  src={TechIcon}
                  alt="tech"
                />
              </div>
              <p className="admin__section-card-description">
                Foundational <br />
                Platform <br />
                technology
              </p>
            </div>
            <div className="admin__section-card">
              <div className="admin__img-container">
                <img
                  className="admin__section-card-logo"
                  src={GrowthSmallLogo}
                  alt="section-card-logo"
                />
                <img
                  className="admin__section-card-icon"
                  src={MobileIcon}
                  alt="mobile app tech"
                />
              </div>
              <p className="admin__section-card-description">
                Mobile <br />
                application <br />
                technology
              </p>
            </div>
            <div className="admin__section-card">
              <div className="admin__img-container">
                <img
                  className="admin__section-card-logo"
                  src={GrowthSmallLogo}
                  alt="section-card-logo"
                />
                <img
                  className="admin__section-card-icon"
                  src={IPIcon}
                  alt="IP"
                />
              </div>
              <p className="admin__section-card-description">
                Software and <br />
                hardware{" "}
                <span className="hide-on-mobile--inline">technical</span>
                <br />
                IP incubation
              </p>
            </div>
            <div className="admin__section-card">
              <div className="admin__img-container">
                <img
                  className="admin__section-card-logo"
                  src={GrowthSmallLogo}
                  alt="section-card-logo"
                />
                <img
                  className="admin__section-card-icon"
                  src={BrandingIcon}
                  alt="branding"
                />
              </div>
              <p className="admin__section-card-description">
                Branding <br />
                and UX <br />
                creation
              </p>
            </div>
            <div className="admin__section-card">
              <div className="admin__img-container">
                <img
                  className="admin__section-card-logo"
                  src={GrowthSmallLogo}
                  alt="section-card-logo"
                />
                <img
                  className="admin__section-card-icon"
                  src={MarketingIcon}
                  alt="marketing"
                />
              </div>
              <p className="admin__section-card-description">
                Lead and <br />
                conversion <br />
                marketing
              </p>
            </div>
            <div className="admin__section-card">
              <div className="admin__img-container">
                <img
                  className="admin__section-card-logo"
                  src={GrowthSmallLogo}
                  alt="section-card-logo"
                />
                <img
                  className="admin__section-card-icon"
                  src={TeamIcon}
                  alt="team"
                />
              </div>
              <p className="admin__section-card-description">
                Skilled <br />
                contributor <br />
                team
              </p>
            </div>
            <div className="admin__section-card">
              <div className="admin__img-container">
                <img
                  className="admin__section-card-logo"
                  src={GrowthSmallLogo}
                  alt="section-card-logo"
                />
                <img
                  className="admin__section-card-icon"
                  src={ExpertIcon}
                  alt="experts"
                />
              </div>
              <p className="admin__section-card-description">
                Expert <br />
                mentors <br />+ execs
              </p>
            </div>
            <div className="admin__section-card">
              <div className="admin__img-container">
                <img
                  className="admin__section-card-logo"
                  src={GrowthSmallLogo}
                  alt="section-card-logo"
                />
                <img
                  className="admin__section-card-icon"
                  src={AdminIcon}
                  alt="admin"
                />
              </div>
              <p className="admin__section-card-description">
                Legal, payroll, <br />
                accounting <br />
                and SEIS/EIS
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button btn-up"
        style={{ width: "56px", height: "56px" }}
        onClick={handleScrollToTop}
      >
        <img src={GrowthUpArrow} alt="vector arrow up" />
      </div>
      <div className="footer">
        <img alt="logo" className="lineal" src={Logo} />
        <p className="copyright">
          {!isMobileView ? (
            <>
              All rights reserved Organic Ventures Ltd
              <br />
              UK - 22 Notting Hill Gate, London, W11 3JE
            </>
          ) : (
            <>
              All rights reserved
              <br /> Organic Ventures Ltd
              <br /> UK - 22 Notting Hill Gate,
              <br /> London, W11 3JE
            </>
          )}
        </p>
      </div>
    </div>
  );
}

export default Landing;
