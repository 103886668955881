import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
// import Articles frosm "./components/Articles";

import Landing from "./screens/Landing";

export default function App() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Landing />} />
        {/* <Route path="/articles" element={<Articles />} />
        <Route path="/magazine" element={<Articles />} /> */}
      </Routes>
    </Fragment>
  );
}
