import "./index.css";

const VentureCard = ({ ventureName, image, hoverImage, hoverColor }) => {
  return (
    <div
      className="venture__card"
      style={{
        "--bg-color": hoverColor,
        "--hover-img": `url(${hoverImage})`,
      }}
    >
      <div
        className="venture__img"
        alt={ventureName}
        style={{ "--img": `url(${image})` }}
      />
      <p className="venture__name">{ventureName}</p>
    </div>
  );
};

export default VentureCard;
