import TrueNewsLogo from "../assets/ventures/true-news-logo.png";
import HealthIdLogo from "../assets/ventures/health-id-logo.png";
import CoexistLogo from "../assets/ventures/coexist-logo.png";
import CupidlyLogo from "../assets/ventures/cupidly-logo.png";
import LovelyLogo from "../assets/ventures/lovely-logo.png";
import SeedLogo from "../assets/ventures/seed-logo.png";

import TrueNewsHoverImage from "../assets/ventures/true-news-hover-image.png";
import HealthIdHoverImage from "../assets/ventures/health-id-hover-image.png";
import CoexistHoverImage from "../assets/ventures/coexist-hover-image.png";
import CupidlyHoverImage from "../assets/ventures/cupidly-hover-image.png";
import LovelyHoverImage from "../assets/ventures/lovely-hover-image.png";
import SeedHoverImage from "../assets/ventures/seed-hover-image.png";

export const VENTURES = [
  {
    ventureName: (
      <>
        Nomad <br /> Tech
      </>
    ),
    image: CoexistLogo,
    hoverColor: "#9affffff",
    hoverImage: CoexistHoverImage,
  },
  {
    ventureName: (
      <>
        Dating <br />
        App
      </>
    ),
    image: CupidlyLogo,
    hoverColor: "#ffa9ddff",
    hoverImage: CupidlyHoverImage,
  },
  {
    ventureName: (
      <>
        Child IQ+EQ <br />
        development
      </>
    ),
    image: SeedLogo,
    hoverColor: "#cdff96ff",
    hoverImage: SeedHoverImage,
  },
  {
    ventureName: (
      <>
        Couples <br />
        Bank
      </>
    ),
    image: LovelyLogo,
    hoverColor: "#ff8da6ff",
    hoverImage: LovelyHoverImage,
  },
  {
    ventureName: (
      <>
        Proactive <br />
        Healthcare
      </>
    ),
    image: HealthIdLogo,
    hoverColor: "#a9ffdeff",
    hoverImage: HealthIdHoverImage,
  },
  {
    ventureName: (
      <>
        Decentralised <br />
        News
      </>
    ),
    image: TrueNewsLogo,
    hoverColor: "#ffbaffff",
    hoverImage: TrueNewsHoverImage,
  },
];
